import React from "react";
import logo from "./images/logo2.png";
import hamburger from "./images/menu.svg";
import close from "./images/cancel.svg";
import "./App.scss";
import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import pfrP from "./images/pfr-poziom.png";
import pfrS from "./images/pfr-srodek.png";

class FormatNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false, isScrolling: false, isClicked: false };
  }

  navActive(r) {
    if (r) {
      this.setState({
        active: false,
      });
      document.getElementById("hamburger").setAttribute("src", hamburger);
      document.getElementById("links").removeAttribute("style");
    } else {
      this.setState({
        active: true,
      });
      document.getElementById("hamburger").setAttribute("src", close);
      document.getElementById("links").setAttribute("style", "display: block");
    }
  }

  navIfActive(r) {
    if (window.innerWidth <= 620) {
      this.navActive(this.state.active);
    }
  }

  render() {
    return (
      <nav>
        <div className="logo">
          <Link activeClass="active" to="home" spy={true} smooth={true}>
            <img src={logo} alt="Format" />
          </Link>
        </div>
        <img
          id="hamburger"
          onClick={() => this.navActive(this.state.active)}
          src={hamburger}
          alt="HAMBURGER"
        />
        <div id="links" className="links">
          <ul>
            <li>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                onClick={() => this.navIfActive()}
              >
                Strona główna
              </Link>
            </li>
            <li>
              <Link
                to="uslugi"
                spy={true}
                smooth={true}
                offset={-70}
                onClick={() => this.navIfActive()}
              >
                Usługi
              </Link>
            </li>
            <li>
              <Link
                to="realizacje"
                spy={true}
                smooth={true}
                offset={-70}
                onClick={() => this.navIfActive()}
              >
                Realizacje
              </Link>
            </li>
            <li>
              <Link
                to="kontakt"
                spy={true}
                smooth={true}
                offset={-70}
                onClick={() => this.navIfActive()}
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

function FormatParallax(props) {
  return (
    <Parallax bgImage={require("./images/parallax.png")} strength={0}>
      <div id="home">
        <div>
          <h1>Format</h1>
          <h3>Meble na wymiar</h3>
        </div>
      </div>
    </Parallax>
  );
}

function FormatHome(props) {
  return (
    <div className="parallax-box">
      <h5>Firma Meblowa FORMAT</h5>
      <p>
        Powstała w 1990 roku i od początku specjalizuje się w produkcji mebli.
        Szczególny nacisk kładziemy na profesjonalne wykonanie. Na życzenie
        klienta tworzymy wirtualne wizualizacje celem dopasowania do gustu
        klienta. Projekty nasze wykonujemy z najlepszych i najnowocześniejszych
        dostępnych na rynku materiałów. Firma Format posiada również w sprzedaży
        sprzęt AGD światowych marek. Nasze produkty dostarczamy własnym
        transportem, montujemy i ustawiamy w cenie zakupionej zabudowy.
        Zapraszamy serdecznie do współpracy.
      </p>
    </div>
  );
}

function FormatServices(props) {
  return (
    <div id="uslugi" className="parallax-box">
      <h5>Nasze Usługi</h5>
      <div className="gridService">
        <div className="service-1">
          <div className="card">
            <img src="images/service1.jpg" alt="..." />
            <div>
              <h5>Projekty komputerowe</h5>
              <p>Tworzymy projekty dla klientów w celu dopasowania mebli.</p>
            </div>
          </div>
        </div>
        <div className="service-2">
          <div className="card">
            <img src="images/service2.jpg" alt="..." />
            <div>
              <h5>Meble kuchenne, Meble pokojowe</h5>
              <p>Tworzymy dla klientów meble kuchenne czy też pokojowe.</p>
            </div>
          </div>
        </div>
        <div className="service-3">
          <div className="card">
            <img src="images/service3.jpg" alt="..." />
            <div>
              <h5>Sypialnie, Zestawy łazienkowe, Zestawy biurowe</h5>
              <p>Tworzymy gotowe zestawy do pokoju, biura czy przedpokoju.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class FormatRealizations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: null, image: null };
  }

  componentDidMount() {
    this.setState({
      modal: document.getElementById("modal"),
      image: document.getElementById("modalImage"),
    });
  }

  renderGallery() {
    var i,
      imgs = [];
    for (i = 3; i <= 37; i++) {
      imgs.push(i);
    }
    const gallery = imgs.map((img) => (
      <img
        key={img.toString()}
        src={`gallery-small/image${img}.jpg`}
        onClick={() => this.modalImage(img)}
        alt="..."
      />
    ));
    return gallery;
  }

  modalImage(img) {
    this.state.modal.setAttribute("style", "display: block");
    this.state.image.setAttribute("src", `gallery/image${img}.jpg`);
  }

  modalCloseWindow(e) {
    if (e.target === document.getElementById("modal")) {
      this.state.modal.setAttribute("style", "");
      this.state.image.setAttribute("src", "");
    }
  }

  modalClose() {
    this.state.modal.setAttribute("style", "");
    this.state.image.setAttribute("src", "");
  }

  render() {
    return (
      <div id="realizacje" className="parallax-box">
        <h5>Realizacje</h5>
        <div className="wrapper">{this.renderGallery()}</div>
        <div
          id="modal"
          style={{ display: "none" }}
          onClick={(e) => this.modalCloseWindow(e)}
        >
          <div id="modalAfter">
            <img
              id="close"
              src={close}
              alt="..."
              onClick={() => this.modalClose()}
            />
            <img id="modalImage" src="" alt="..." />
          </div>
        </div>
      </div>
    );
  }
}

class FormatContact extends React.Component {
  render() {
    return (
      <div id="kontakt" className="parallax-box">
        <h5>Kontakt</h5>
        <div className="contactGrid">
          <div className="contact-1">
            <ul className="list-group ">
              <li className="right">
                <h5>GODZINY OTWARCIA:</h5>
                <p>
                  poniedziałek <b>08:00 - 16:00</b>
                </p>
                <p>
                  wtorek <b>08:00 - 16:00</b>
                </p>
                <p>
                  środa <b>08:00 - 16:00</b>
                </p>
                <p>
                  czwartek <b>08:00 - 16:00</b>
                </p>
                <p>
                  piątek <b>08:00 - 16:00</b>
                </p>
                <p>
                  sobota <b>nieczynne</b>
                </p>
                <p>
                  niedziela <b>nieczynne</b>
                </p>
              </li>
            </ul>
          </div>
          <div className="contact-2">
            <ul className="list-group">
              <li>
                <h5>ADRES:</h5>
                <p>ul. Przedborska 245</p>
                <p>97-500 Radomsko</p>
              </li>
              <li>
                <h5>TELEFON:</h5>
                <p>tel. kom. 602 666 707</p>
              </li>
              <li>
                <h5>E-MAIL:</h5>
                <p>
                  <a href="mailto:ewa@format.net.pl">ewa@format.net.pl</a>
                </p>
              </li>
              <li className="hidden">
                <h5>GODZINY OTWARCIA:</h5>
                <p>
                  poniedziałek <b>08:00 - 16:00</b>
                </p>
                <p>
                  wtorek <b>08:00 - 16:00</b>
                </p>
                <p>
                  środa <b>08:00 - 16:00</b>
                </p>
                <p>
                  czwartek <b>08:00 - 16:00</b>
                </p>
                <p>
                  piątek <b>08:00 - 16:00</b>
                </p>
                <p>
                  sobota <b>nieczynne</b>
                </p>
                <p>
                  niedziela <b>nieczynne</b>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="map-grid">
          <div className="map">
            <iframe
              title="Google Embed"
              src="https://maps.google.com/maps?hl=pl&amp;q=ul. Przedborska 245+(Format Meble), 97-500 Radomsko&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
        </div>
        <img id="pfrp" src={pfrP} alt="Plansza informacyjna PFR" />
        <img id="pfrs" src={pfrS} alt="Plansza informacyjna PFR" />
      </div>
    );
  }
}

function FormatFooter(props) {
  return (
    <div className="parallax-box">
      <footer className="footerGrid">
        <div className="footer-1">
          <img src="images/logo3.png" alt="" />
        </div>
        <div className="footer-2">
          <h6>KONTAKT</h6>
          <p>Format MEBLE</p>
          <p>ul. Przedborska 245</p>
          <p>97-500 Radomsko</p>

          <p>ewa@format.net.pl</p>
          <p>tel. kom. 602 666 707</p>
        </div>
        <div className="footer-3">
          <h6>STRONY</h6>
          <p>
            <Link to="home" smooth={true}>
              Strona główna
            </Link>
          </p>
          <p>
            <Link to="uslugi" smooth={true} offset={-70}>
              Usługi
            </Link>
          </p>
          <p>
            <Link to="realizacje" smooth={true} offset={-70}>
              Realizacje
            </Link>
          </p>
          <p>
            <Link to="kontakt" smooth={true} offset={-70}>
              Kontakt
            </Link>
          </p>
        </div>
      </footer>
    </div>
  );
}

class App extends React.Component {
  render() {
    return (
      <>
        <FormatNav />
        <FormatParallax />
        <FormatHome />
        <FormatServices />
        <FormatRealizations />
        <FormatContact />
        <FormatFooter />
      </>
    );
  }
}

export default App;
